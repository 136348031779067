:root {
  --bg-primary: #1a1a1a;
  --bg-secondary: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #b3b3b3;
  --accent: #6366f1;
  --accent-hover: #4f46e5;
  --error: #ef4444;
  --border: #404040;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}

.App {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.App-header {
  text-align: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
}

.input-section {
  order: 2;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
}

.settings-grid {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  background-color: var(--bg-secondary);
  padding: 0.75rem;
  border-radius: 6px;
  font-size: 0.9rem;
  max-width: 800px;
  margin: 0.5rem auto;
  position: relative;
  z-index: 1;
}

.settings-grid h3 {
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
  color: var(--text-secondary);
  grid-column: 1 / -1;
}

.bookbinding-guides {
  order: 3;
  background-color: var(--bg-secondary);
  border-radius: 6px;
  padding: 0.75rem;
  margin-top: 0.5rem;
  width: 100%;
  max-width: 800px;
  width: 100%;
  margin: 0.5rem auto;
}

.bookbinding-guides h3 {
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
  color: var(--text-secondary);
}

.guide-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
  width: 100%;
  align-items: center;
  font-size: 0.9rem;
}

.guide-options .input-group {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.guide-options .input-group label {
  white-space: nowrap;
}

input[type="number"] {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border);
  color: var(--text-primary);
  padding: 0.2rem;
  border-radius: 4px;
  width: 60px;
  font-size: 0.9rem;
}

input[type="checkbox"] {
  accent-color: var(--accent);
}

button {
  padding: 0.5rem 1rem;
  background-color: var(--accent);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.2s ease;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

button:disabled {
  background-color: var(--border);
  cursor: not-allowed;
  opacity: 0.7;
}

button:hover:not(:disabled) {
  background-color: var(--accent-hover);
  transform: translateY(-1px);
}

.error-message {
  color: var(--error);
  padding: 1rem;
  border: 1px solid var(--error);
  border-radius: 8px;
  margin-top: 1rem;
  background-color: rgba(239, 68, 68, 0.1);
}

.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  z-index: 1000;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: var(--accent);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.pdf-preview {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.pdf-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.pdf-controls button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

.pdf-controls span {
  color: var(--text-secondary);
  min-width: 120px;
  text-align: center;
}

.pdf-container {
  background-color: var(--bg-primary);
  padding: 1rem;
  border-radius: 4px;
  max-width: 100%;
  overflow: auto;
}

.pdf-container canvas {
  max-width: 100%;
  height: auto !important;
}

.loading, .error {
  color: var(--text-secondary);
  padding: 2rem;
  text-align: center;
}

/* Adjust the preview container size */
.react-pdf__Document {
  display: flex;
  justify-content: center;
  background-color: var(--bg-primary);
}

.react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Make the preview responsive */
@media (max-width: 600px) {
  .pdf-controls {
    flex-direction: column;
  }
  
  .pdf-container {
    padding: 0.5rem;
  }
}

.page-layout-diagram {
  width: 100%;
  max-width: 800px;
  background: var(--bg-secondary);
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem auto;
}

.diagram-section {
  margin-bottom: 16px;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.diagram-section:last-child {
  margin-bottom: 0;
}

.diagram-section h3 {
  margin-bottom: 10px;
  color: var(--text-primary);
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  animation-delay: 0.2s;
}

.page-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.page-row:last-child {
  margin-bottom: 0;
}

.page-card {
  position: relative;
  width: 45px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(248, 248, 248, 0.8);
  border: 1px solid #333;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  color: black;
  padding: 2px;
}

.page-card.landscape {
  width: 75px;
  height: 48px;
  font-size: 13px;
}

.page-card.blank-page {
  background: rgba(248, 248, 248, 0.8);
}

.page-card.blank-page .page-numbers span {
  color: black;
}

h1 {
  font-size: 2rem;
  margin: 0;
  letter-spacing: 0.05em;
}

.App-header p {
  font-size: 0.8rem;
  margin: 0.25rem 0;
}

.debug-section {
  order: 4;
  max-width: 800px;
  width: 100%;
  margin: 0.5rem auto;
  padding: 0.5rem;
  border-top: 1px solid var(--border);
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.debug-controls {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: left;
}

.debug-controls label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.page-numbers {
  display: flex;
  gap: 12px;
  font-size: 11px;
  width: 100%;
  justify-content: center;
  padding: 0 6px;
}

.page-numbers span {
  display: block;
  color: black;
  text-align: center;
}

.page-guides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.fold-line {
  position: absolute;
  left: 50%;
  top: 10%;
  bottom: 10%;
  width: 1px;
  border-left: 1px dotted rgba(0, 0, 0, 0.9);
}

.hole-guides {
  position: absolute;
  left: 50%;
  top: 10%;
  bottom: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateX(-50%);
}

.hole {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
}

/* Add responsive breakpoints */
@media (max-width: 768px) {
  .App {
    padding: 8px;
    width: calc(100% - 16px);
  }
  
  .settings-grid,
  .bookbinding-guides,
  .interactive-diagram,
  .page-layout-diagram,
  button:not(.skip-tutorial):not(.close-button),
  .privacy-notice.standalone {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
  }

  .settings-grid {
    grid-template-columns: 1fr;
    padding: 0.5rem;
    gap: 0.5rem;
  }
  
  .guide-options {
    grid-template-columns: 1fr;
  }

  .page-card {
    width: 36px;
    height: 45px;
    font-size: 11px;
  }

  .page-card.landscape {
    width: 60px;
    height: 39px;
    font-size: 11px;
  }

  .page-row {
    gap: 12px;
    padding: 8px;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .page-pair {
    margin: 0 8px;
  }

  .diagram-section {
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 4px;
    width: calc(100% - 8px);
  }

  .settings-grid,
  .bookbinding-guides,
  .interactive-diagram,
  .page-layout-diagram,
  button:not(.skip-tutorial):not(.close-button),
  .privacy-notice.standalone {
    width: calc(100% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }

  .page-card {
    width: 32px;
    height: 40px;
    font-size: 10px;
  }

  .page-card.landscape {
    width: 52px;
    height: 34px;
    font-size: 10px;
  }

  .page-row {
    gap: 8px;
    padding: 4px;
  }

  .page-pair {
    gap: 4px;
    margin: 0 6px;
  }

  .settings-grid,
  .bookbinding-guides {
    padding: 0.4rem;
  }

  .input-group {
    font-size: 0.8rem;
  }

  .App-header h1 {
    font-size: 1.3rem;
  }

  .definition {
    font-size: 0.8rem;
  }
}

/* Ensure all containers respect viewport width */
.App,
.settings-grid,
.bookbinding-guides,
.interactive-diagram,
.page-layout-diagram,
.diagram-section,
.page-row,
button,
.privacy-notice.standalone {
  max-width: 100%;
  box-sizing: border-box;
}

/* Remove any fixed widths that might cause overflow */
.input-group,
.guide-options,
.page-layout-diagram,
.diagram-section {
  width: 100%;
  box-sizing: border-box;
}

/* Ensure proper nesting of flex containers */
.input-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Add this to prevent horizontal scroll on the body */
body {
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
}

/* Ensure proper box-sizing for all elements */
* {
  box-sizing: border-box;
}

.page-card.debug-numbers .page-numbers span:not(:empty) {
  color: #ef4444;
  font-weight: bold;
}

.page-pair {
  display: flex;
  gap: 6px;
  margin: 0 8px;
}

/* Add these styles to the existing .page-layout-diagram styles */
.page-layout-diagram.placeholder {
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-secondary);
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
}

.placeholder-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem;
  color: var(--text-secondary);
}

.placeholder-content p {
  margin: 0;
  font-size: 0.9rem;
}

.placeholder-illustration {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  opacity: 0.5;
}

.placeholder-page {
  width: 40px;
  height: 52px;
  background: var(--text-secondary);
  border-radius: 3px;
}

.placeholder-arrow {
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.placeholder-booklet {
  position: relative;
  width: 60px;
  height: 52px;
}

.placeholder-sheet {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--text-secondary);
  border-radius: 3px;
}

.placeholder-sheet:nth-child(1) {
  transform: rotate(-5deg);
}

.placeholder-sheet:nth-child(2) {
  transform: rotate(5deg);
}

.interactive-diagram {
  order: 2;
  margin-top: 0 !important;
  cursor: pointer;
  transition: all 0.2s ease;
  max-width: 800px;
  width: 100%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.interactive-diagram.drag-active {
  transform: scale(1.02);
  box-shadow: 0 0 0 2px var(--accent);
}

.interactive-diagram.drag-active .page-layout-diagram.placeholder {
  background-color: rgba(99, 102, 241, 0.1);
}

/* Update the placeholder styles */
.page-layout-diagram.placeholder {
  transition: all 0.2s ease;
}

.placeholder-content {
  cursor: pointer;
}

.placeholder-content p {
  transition: all 0.2s ease;
}

.interactive-diagram:hover .placeholder-content p {
  color: var(--accent);
}

.interactive-diagram:hover .placeholder-illustration {
  opacity: 0.7;
}

/* Update Tutorial Styles */
.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden;
}

.tutorial-highlight {
  position: fixed;
  border: 2px solid var(--accent);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 1001;
  transition: all 0.3s ease;
}

.tutorial-content {
  position: fixed;
  background: var(--bg-secondary);
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 300px;
  z-index: 1002;
  transform: translateX(-50%);
  transition: all 0.3s ease;
}

.tutorial-skip-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1003;
}

.tutorial-progress {
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.4s ease;
  transition-delay: 0.2s;  /* Delay the progress dots */
}

.tutorial-overlay.visible .tutorial-progress {
  opacity: 1;
  transform: translateY(0);
}

.tutorial-content p {
  margin: 0;
  line-height: 1.5;
  font-size: 0.9rem;
}

/* Add a class for when the content is transitioning */
.tutorial-content.transitioning {
  opacity: 0;
  pointer-events: none;
}

/* Add fade-in animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update diagram section styles */
.diagram-section {
  margin-bottom: 16px;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

/* Add delay for second diagram section */
.diagram-section:nth-child(2) {
  animation-delay: 0.3s;
}

/* Add fade-in for the diagram headers */
.diagram-section h3 {
  margin-bottom: 10px;
  color: var(--text-primary);
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  animation-delay: 0.2s;
}

.diagram-section:nth-child(2) h3 {
  animation-delay: 0.5s;
}

.definition {
  color: var(--text-secondary);
  font-size: 1rem;
  line-height: 1.6;
  margin: 0.75rem 0;
  font-family: serif;
}

.definition .word {
  font-weight: bold;
  color: var(--text-primary);
  font-size: 1.1rem;
}

.definition .pronunciation {
  font-family: monospace;
  color: var(--text-secondary);
  font-size: 1rem;
}

.definition .type {
  font-style: italic;
  color: var(--text-secondary);
}

.definition .meaning {
  display: inline-block;
  margin-top: 0.2rem;
  color: var(--text-secondary);
}

.privacy-notice {
  font-size: 0.8rem;
  color: var(--text-secondary);
  opacity: 0.8;
  margin-top: 1rem !important;
  font-style: italic;
}

.pdf-title {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin-bottom: 0.75rem;
  text-align: center;
  max-width: 800px;
  width: 100%;
  word-break: break-word;
  position: absolute;
  top: 2.5rem;
  left: 0;
}

.processing-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.processing-panel {
  background: var(--bg-secondary);
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--text-secondary);
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: auto;
  margin: 0;
}

.close-button:hover {
  color: var(--text-primary);
}

.processing-content,
.download-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
}

.fair-use-section {
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  width: 100%;
}

.fair-use-label {
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  font-size: 0.9rem;
  line-height: 1.4;
  color: var(--text-secondary);
  cursor: pointer;
}

.fair-use-label input[type="checkbox"] {
  margin-top: 0.2rem;
}

.download-button {
  background-color: var(--accent);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s ease;
  width: 100%;
}

.download-button:disabled {
  background-color: var(--border);
  cursor: not-allowed;
  opacity: 0.7;
}

.download-button:hover:not(:disabled) {
  background-color: var(--accent-hover);
  transform: translateY(-1px);
}

.filename {
  font-size: 0.9rem;
  color: var(--text-secondary);
  word-break: break-all;
  margin: 0;
}

.file-size-limit {
  font-size: 0.8rem;
  color: var(--text-secondary);
  opacity: 0.8;
  margin-top: -1rem !important;
  font-style: italic;
}

/* Only apply transitions for entering/exiting states */
.tutorial-overlay:not(.visible) .tutorial-highlight,
.tutorial-overlay:not(.visible) .tutorial-content {
  transition: opacity 0.3s ease;
}

button[disabled], 
button:not(.skip-tutorial):not(.close-button) {
  order: 5;
}

/* Add these styles where other input styles are defined */
select {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border);
  color: var(--text-primary);
  padding: 0.2rem;
  border-radius: 4px;
  font-size: 0.9rem;
  width: auto;
  cursor: pointer;
}

select:hover {
  border-color: var(--accent);
}

select option {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

/* Update the input-group styles to better handle the select */
.input-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.input-group select {
  width: 100%;
}

/* Add these styles where other button styles are defined */
.debug-controls button {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border);
  font-size: 0.8rem;
  font-weight: normal;
  opacity: 0.8;
}

.debug-controls button:hover:not(:disabled) {
  background-color: var(--border);
  transform: none;
}

/* Add this style for the privacy notice */
.debug-section .privacy-notice {
  text-align: center;
  font-size: 0.8rem;
  color: var(--text-secondary);
  margin-top: 1rem;
  opacity: 0.8;
}

/* Update the privacy notice styles */
.privacy-notice.standalone {
  text-align: center;
  font-size: 0.8rem;
  color: var(--text-secondary);
  margin: 1rem auto;
  opacity: 0.8;
  max-width: 800px;
  width: 100%;
  order: 6;
}

/* Add this with the other diagram styles */
.hidden-pages-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  color: var(--text-secondary);
  margin: 4px 0;
}

.ellipsis-icon {
  opacity: 0.6;
}

/* Add consistent max-width for main components */
.settings-grid,
.bookbinding-guides,
.interactive-diagram,
.page-layout-diagram,
button:not(.skip-tutorial):not(.close-button),
.privacy-notice.standalone {
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Update responsive styles */
@media (max-width: 768px) {
  .App {
    padding: 8px;
    width: calc(100% - 16px);
  }
  
  .settings-grid,
  .bookbinding-guides,
  .interactive-diagram,
  .page-layout-diagram,
  button:not(.skip-tutorial):not(.close-button),
  .privacy-notice.standalone {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-right: 8px;
  }

  /* ... rest of media query styles ... */
}

@media (max-width: 480px) {
  .App {
    padding: 4px;
    width: calc(100% - 8px);
  }

  .settings-grid,
  .bookbinding-guides,
  .interactive-diagram,
  .page-layout-diagram,
  button:not(.skip-tutorial):not(.close-button),
  .privacy-notice.standalone {
    width: calc(100% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }

  /* ... rest of media query styles ... */
}

/* Version indicator styles */
.version-indicator {
  position: fixed;
  top: 8px;
  left: 8px;
  z-index: 100;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.version-number {
  cursor: help;
  padding: 4px 8px;
  border-radius: 4px;
  transition: opacity 0.2s ease;
}

.version-number:hover {
  color: var(--text-primary);
}

.changelog-popup {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 8px;
  background-color: var(--bg-secondary);
  border-radius: 6px;
  padding: 1rem;
  min-width: 240px;
  box-shadow: 0 4px 12px rgb(0, 0, 0);
  font-size: 0.85rem;
  animation: fadeIn 0.2s ease;
  z-index: 1000;
  border: 1px solid var(--border);
}

.changelog-entry {
  margin-bottom: 1rem;
}

.changelog-entry:last-child {
  margin-bottom: 0;
}

.changelog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.changelog-version {
  font-weight: bold;
  color: var(--text-primary);
}

.changelog-date {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.changelog-changes {
  margin: 0;
  padding-left: 1.2rem;
  color: var(--text-secondary);
}

.changelog-changes li {
  margin: 0.25rem 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .version-indicator {
    top: 4px;
    left: 4px;
    font-size: 0.75rem;
  }
  
  .changelog-popup {
    min-width: 200px;
  }
}

/* Update page numbers container for landscape pages */
.page-card.landscape .page-numbers {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 4px;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
}

/* Position the individual page numbers */
.page-card.landscape .page-numbers span {
  position: absolute;
  width: auto;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Position first number at 25% of page width */
.page-card.landscape .page-numbers span:first-child {
  left: 25%;
}

/* Position second number at 75% of page width */
.page-card.landscape .page-numbers span:last-child {
  left: 75%;
}

/* Keep portrait page numbers centered */
.page-card:not(.landscape) .page-numbers {
  display: flex;
  gap: 12px;
  font-size: 11px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
}

.hole-guides-group {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.hole-count-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 0.5rem;
}

.hole-count-label input[type="number"] {
  opacity: 0.5;
  cursor: not-allowed;
}

.hole-count-label input[type="number"]:not([disabled]) {
  opacity: 1;
  cursor: auto;
}

/* Add these styles */
.custom-paper-size {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 0;
  align-items: center;
  border-top: none;
  margin-top: 0.5rem;
}

.custom-paper-size .input-group {
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0.25rem 0;
}

.custom-paper-size input[type="number"] {
  width: 60px;
}

/* Add styles for the units toggle */
.units-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.units-toggle label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
}

/* Update responsive styles */
@media (max-width: 480px) {
  .custom-paper-size {
    margin-left: 0;
    padding: 0.75rem 0 0.25rem 0;
    border-left: none;
    border-top: 1px solid var(--border);
    margin-top: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
  }

  .custom-paper-size .input-group {
    font-size: 0.8rem;
    padding: 0.25rem 0;
  }

  .custom-paper-size input[type="number"] {
    width: 50px;
  }

  .units-toggle {
    font-size: 0.8rem;
  }
}

/* Add spacing between panels */
.settings-grid + .settings-grid,
.settings-grid + .bookbinding-guides {
  margin-top: 1rem;
}

/* Update paper size container styles */
.paper-size-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.paper-size-container select {
  width: 100%;
  margin-bottom: 0;
}

/* Update custom paper size styles */
.custom-paper-size {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  padding: 0;
  align-items: center;
  border-top: none;
  margin-top: 0.5rem;
}

/* Add these new styles */
.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.panel-header:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.panel-header h3 {
  margin: 0;
  user-select: none;
}

.collapse-arrow {
  font-size: 0.8rem;
  color: var(--text-secondary);
  transition: transform 0.3s ease;
  user-select: none;
}

.collapse-arrow.expanded {
  transform: rotate(0deg);
}

.collapse-arrow:not(.expanded) {
  transform: rotate(-90deg);
}

.panel-content {
  animation: slideDown 0.3s ease;
  overflow: hidden;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Update existing styles */
.settings-grid h3,
.bookbinding-guides h3,
.debug-section h3 {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin: 0;
}

.settings-grid,
.bookbinding-guides,
.debug-section {
  background-color: var(--bg-secondary);
  border-radius: 6px;
  overflow: hidden;
}

/* Adjust spacing for collapsed state */
.settings-grid:not(:first-child),
.bookbinding-guides,
.debug-section {
  margin-top: 0.75rem;
}

/* Add these new styles for the output preview section */
.output-preview-section {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 2rem;
  order: 1;
}

/* Update existing styles */
.input-section {
  order: 2;
  /* ... rest of existing input-section styles ... */
}

/* Update the interactive diagram styles */
.interactive-diagram {
  order: 2; /* Changed from 1 */
  /* ... rest of existing interactive-diagram styles ... */
}

/* Update the page-layout-diagram styles to fit better at the top */
.page-layout-diagram {
  margin-top: 1rem;
  /* ... rest of existing page-layout-diagram styles ... */
}

/* Ensure proper spacing between sections */
.App {
  gap: 0.5rem; /* Reduced from 1rem to tighten up the layout */
}

/* Update the App-header margin */
.App-header {
  margin-bottom: 0.5rem; /* Reduced from 1rem */
}

/* Adjust responsive styles */
@media (max-width: 768px) {
  .output-preview-section {
    width: calc(100% - 16px);
    margin: 0 8px 1rem;
  }
}

@media (max-width: 480px) {
  .output-preview-section {
    width: calc(100% - 8px);
    margin: 0 4px 0.75rem;
  }
}

/* Add these styles to your existing CSS */
.donation-panel {
  background: rgba(99, 102, 241, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.donation-panel p {
  margin: 0 0 1rem 0;
  color: var(--text-primary);
  font-weight: 600;
}

.donation-amounts {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.donation-button {
  background: var(--accent);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.5rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  width: auto;
}

.donation-button:hover {
  background: var(--accent-hover);
  transform: translateY(-1px);
}

.donation-button.selected {
  background: var(--accent-hover);
  box-shadow: 0 0 0 2px var(--accent);
}

.donation-note {
  font-size: 0.9rem;
  color: var(--text-secondary) !important;
  margin-top: 0.5rem;
}

/* Update existing processing panel styles */
.processing-panel {
  width: 90%;
  max-width: 400px;
}

.download-content {
  gap: 1.5rem;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .donation-amounts {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .donation-button {
    width: 100%;
  }
}

/* Add these new styles */
.page-layout-controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
}

.padding-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.input-group.horizontal {
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

/* Update existing input-group styles */
.input-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .page-layout-controls {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .padding-controls {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .input-group.horizontal {
    flex-direction: column;
    align-items: flex-start;
  }
}